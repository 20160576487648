import React from 'react';
import DataProvider from './provider/DataProvider';
import AuthProvider, { Login, UserContext } from './provider/AuthProvider';
import { RentManagement } from './applications/RentManagement';
import LandingPage from './components/rentmanagement/LandingPage';
import { NavLink, Switch, Route } from 'react-router-dom';

const Header = ({ home, navlinks }) => {
  const [_, LoginLogout] = React.useContext(UserContext);
  return (
    <div className="Header bg-white shadow-md">
      <div className="container flex flex-wrap items-center px-3 py-6 mx-auto font-sans text-gray-700">
        {/* LOGO */}
        <div className="sm:mx-2">
          <a onClick={home} className="text-indigo-500 active:outline-none">
            <div className="sm:w-16 sm:h-16 hover:shadow-md w-8 h-8">
              <img src="./nlogo512.png" />
            </div>
          </a>
        </div>

        {/* BUTTONS */}
        <div className="justify-items-stretch flex items-center justify-between flex-grow">
          {/* ACTIONS */}
          <div className="flex flex-row">
            {navlinks &&
              navlinks.map((navLink, i) => (
                <div key={i} className="sm:mx-2">
                  {navLink}
                </div>
              ))}
          </div>

          {/* LOGIN/OUT */}
          <LoginLogout />
        </div>
      </div>
    </div>
  );
};

function App() {
  return (
    <div className="relative flex flex-col h-full">
      <AuthProvider
        publicpage={
          <>
            <div className="xl:max-w-5xlxl flex-grow w-full px-5 mx-auto my-8">
              <LandingPage />
            </div>
          </>
        }
        header={(goToHome, navLinks) => (
          <Header home={goToHome} navlinks={navLinks} />
        )}
        protectedpage={() => (
          <div className="xl:max-w-5xlxl flex-grow w-full px-5 mx-auto my-8">
            <DataProvider>
              <RentManagement />
            </DataProvider>
          </div>
        )}
        footer={
          <>
            <div className="Footer flex-shrink-0">
              <div className="md:flex flex-wrap justify-around w-full py-8 text-lg text-center text-white bg-white">
                <p className="xl:text-center my-2 text-gray-500">
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/in/kappelmans/"
                    className="hover:underline hover:text-gray-700"
                  >
                    <i className="fab fa-linkedin"></i> Kris Appelmans
                  </a>
                </p>

                <p className="xl:text-center my-2 text-gray-500">
                  <i className="fas fa-phone"></i>&nbsp;+32 498 33 04 73
                </p>

                <p className="xl:text-center my-2 text-gray-500">
                  &copy; 2020{' '}
                  <a
                    target="_blank"
                    href="https://www.kreva.be"
                    className="hover:underline hover:text-gray-700"
                  >
                    KrEva
                  </a>
                  . All rights reserved.
                </p>
              </div>
            </div>
          </>
        }
      ></AuthProvider>
    </div>
  );
}

export default App;
