import React, { useContext, useState, useEffect } from 'react';
import { EntityList } from '../components/EntityList';
import { Link } from 'react-router-dom';
import { DataContext } from '../provider/DataProvider';
import { PROPERTY_TYPE } from './StaticProperties';
import RentAdjustment from '../components/rentmanagement/RentAdjustment';

export const contractRentAdjustment = () => ({
  detail: {
    rent: '',
    charges: '',
    indexType: '',
    indexBase: '',
    language: '2',
  },
  new_rent: '',
  new_index: '',
  baseIndexRebased: '',
  owner: '',
  property: '',
  contract: '',
});

export const ENTITY_NAME = 'contractRentAdjustment';

export const ContractRentAdjustmentList = ({
  indexMonth,
  newIndex,
  listActions,
  customActions,
  readonly,
}) => {
  const [{ property }, { calculateIndex }] = useContext(DataContext);

  return (
    <div>
      <EntityList
        entityName={ENTITY_NAME}
        listActions={listActions}
        displayFields={[
          //{ name: 'ID', path: '_id' },
          { name: 'Eigenaar', path: 'owner.name' },
          { name: 'Huurder', path: 'contract.name' },
          { name: 'Basis huur', path: 'contract.detail.rent' },
          { name: 'Nieuwe huur', path: 'detail.rent' },
          { name: 'Kosten', path: 'contract.detail.charges' },
          { name: 'Prov OV', path: 'contract.detail.provrv' },
          { name: 'BTW', path: 'detail.vat' },
          { name: 'Totaal', path: 'detail.monthlyDue' },
        ]}
        customActions={customActions}
        readonly={readonly}
      ></EntityList>
    </div>
  );
};
