import React, { Fragment, useEffect, useState } from 'react';
import styles from './RentAdjustment.module.css';
import { getContractLanguage } from '../../model/StaticProperties';
import { trans } from './RentAdjustment';
import { getEntity } from '../../firebase';

export const RentAdjustmentDetail = ({
  contractRentAdjustment,
  indexationId,
}) => {
  const [lRentAdjustment, setRentAdjustment] = useState(contractRentAdjustment);
  const [lang, setLang] = useState(undefined);

  useEffect(() => {
    lRentAdjustment &&
      setLang(getContractLanguage(lRentAdjustment.contract.detail.language));
  }, [lRentAdjustment]);

  useEffect(() => {
    indexationId &&
      getEntity(
        'contractRentAdjustment',
        indexationId,
        setRentAdjustment,
        false,
      );
  }, []);

  const translation = trans();
  const today = new Date();

  const SignBy = ({ signby }) => {
    const signbyList = signby ? signby.split('\\n') : [];
    return (
      <>
        {signbyList.map((el) => (
          <div>{el}</div>
        ))}
      </>
    );
  };

  return lRentAdjustment && lang ? (
    <div className={styles.rentadjustment}>
      {lRentAdjustment.owner.banner ? (
        <Fragment>
          <div className={`${styles.banner} border-2 border-black p-1`}>
            <img src={lRentAdjustment.owner.banner}></img>
          </div>

          <div className={styles.heading}>
            <div></div>

            <div className={styles.recipient_w_header}>
              <div></div>
              <div>
                <div className={styles.name}>
                  {lRentAdjustment.contract.name}
                </div>
                <div className={styles.street}>
                  {lRentAdjustment.contract.address.street}
                </div>
                <div className={styles.city}>
                  {lRentAdjustment.contract.address.postalcode}{' '}
                  {lRentAdjustment.contract.address.place}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <div className={styles.heading}>
          <div>
            {lRentAdjustment.owner.logo && (
              <div>
                <img src={lRentAdjustment.owner.logo}></img>
              </div>
            )}
            <div className={styles.name}>{lRentAdjustment.owner.name}</div>
            <div className={styles.street}>
              {lRentAdjustment.owner.address.street}
            </div>
            <div className={styles.city}>
              {lRentAdjustment.owner.address.postalcode}{' '}
              {lRentAdjustment.owner.address.place}
            </div>
            <div className={styles.enterprisenbr}>
              {lRentAdjustment.owner.enterprisenbr}
            </div>
            <div className={styles.break}></div>
            {lRentAdjustment.owner.phone && (
              <div className={styles.phone}>
                Tel: {lRentAdjustment.owner.phone}
              </div>
            )}
            {lRentAdjustment.owner.email && (
              <div className={styles.email}>
                email: {lRentAdjustment.owner.email}
              </div>
            )}
          </div>

          <div className={styles.recipient}>
            <div></div>
            <div>
              <div className={styles.name}>{lRentAdjustment.contract.name}</div>
              <div className={styles.street}>
                {lRentAdjustment.contract.address.street}
              </div>
              <div className={styles.city}>
                {lRentAdjustment.contract.address.postalcode}{' '}
                {lRentAdjustment.contract.address.place}
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <div className={styles.l1}>
          {lRentAdjustment.owner.address.place}, {today.getDate()}{' '}
          {translation[lang]['month'][today.getMonth()]}{' '}
          {today.getYear() + 1900}
        </div>
        <div className={styles.l2}>{translation[lang].l2(lRentAdjustment)}</div>
        <div className={styles.l3}>{translation[lang].l3(lRentAdjustment)}</div>
        <div className={styles.l4}>{translation[lang].l4(lRentAdjustment)}</div>

        <div className="grid items-center grid-cols-2">
          <div className="grid items-center grid-cols-1">
            <div className={styles.l5}>
              {translation[lang].l5(lRentAdjustment)}
            </div>
            <div className={styles.l6}>
              {translation[lang].l6(lRentAdjustment)}
            </div>
          </div>
          <div className={styles.l7}>
            {translation[lang].l7(lRentAdjustment)}
          </div>
        </div>

        <div className="grid items-center grid-cols-2">
          {translation[lang].l8(lRentAdjustment)}
          {translation[lang].l9(lRentAdjustment)}
          {translation[lang].l9b(lRentAdjustment)}
          {translation[lang].l9c && translation[lang].l9c(lRentAdjustment)}
          {translation[lang].l10(lRentAdjustment)}
        </div>
        <div className={styles.l11}>
          {translation[lang].l11(lRentAdjustment)}
        </div>
        <div className={styles.l12}>
          {translation[lang].l12(lRentAdjustment)}
        </div>
        <div className={styles.l13}>
          <div>
            <img src={lRentAdjustment.owner.signature}></img>
          </div>
          <SignBy signby={lRentAdjustment.owner.signby}></SignBy>
        </div>
      </div>
    </div>
  ) : (
    <div>loading...</div>
  );
};

export default RentAdjustmentDetail;
