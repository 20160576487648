import React, { useContext, useState, useEffect } from 'react';
import { EntityList } from '../components/EntityList';

import { DataContext } from '../provider/DataProvider';
import { PROPERTY_TYPE } from './StaticProperties';

import EntityDetail, {
  VIEW_STATES,
  PropertyText,
  PropertySelect,
} from '../components/EntityDetail';

export const propertyTemplate = () => ({
  name: 'name',
  address: {
    place: 'place',
    postalcode: 'code',
    street: 'street',
  },
  type: '',
  owner: '',
  own_ref: 'demo-prop',
  detail: {
    KI: '',
    surface: '',
  },
});

export const ENTITY_NAME = 'property';

export const usePropertyList = () => {
  const [{ property }] = useContext(DataContext);
  const [propertyList, setPropertyList] = React.useState(undefined);

  useEffect(() => {
    if (property.length > 0) {
      setPropertyList(
        property.map((it) => ({
          key: it.own_ref ? it.own_ref : it._id,
          value: it.name,
        })),
      );
    }
  }, [property]);

  return propertyList;
};

export const PropertyList = () => {
  return (
    <EntityList
      entityName={ENTITY_NAME}
      sortFunction={(contract1, contract2) => {
        return contract1.name < contract2.name ? -1 : 1;
      }}
      displayFields={[
        //{ name: 'ID', path: '_id' },
        { name: 'Naam', path: 'name' },
        { name: 'Straat', path: 'address.street' },
        { name: 'Plaats', path: 'address.place' },
        /*{
               name: 'Archived',
               path: 'archive',
               render: (val) => (val ? 'Archived' : ''),
            },*/
        { name: 'Eigenaar', path: 'owner' },
      ]}
    ></EntityList>
  );
};

export const PropertyDetail = ({ propertyId }) => {
  const [propertyDetail, setPropertyDetail] = useState(
    propertyId === 'new' ? propertyTemplate() : undefined,
  );
  const [viewState, setViewState] = useState(
    propertyId === 'new' ? VIEW_STATES.NEW : VIEW_STATES.VIEW,
  );

  const [{ owner }] = useContext(DataContext);
  const [ownerList, setOwnerList] = useState(undefined);

  useEffect(() => {
    if (owner.length > 0) {
      console.dir(owner);
      setOwnerList(
        owner.map((it) => ({
          key: it.own_ref ? it.own_ref : it._id,
          value: it.name,
        })),
      );
    }
  }, [owner]);
  return (
    <EntityDetail
      on_back={ENTITY_NAME}
      entityId={propertyId}
      entityName={ENTITY_NAME}
      entityDetail={ownerList && propertyDetail}
      setEntityDetail={setPropertyDetail}
      viewState={viewState}
      setViewState={setViewState}
      secured
    >
      <div className="sm:gap-3 sm:grid-cols-3 md:grid-cols-4 grid items-center grid-cols-1">
        {ownerList && (
          <PropertySelect
            path="owner"
            entityDetail={propertyDetail}
            setEntityDetail={setPropertyDetail}
            label="Eigenaar"
            edit={viewState !== VIEW_STATES.VIEW}
            keyVal={ownerList}
          ></PropertySelect>
        )}

        <PropertySelect
          path="type"
          entityDetail={propertyDetail}
          setEntityDetail={setPropertyDetail}
          label="Type"
          edit={viewState !== VIEW_STATES.VIEW}
          keyVal={PROPERTY_TYPE.map((it) => ({
            key: it.key,
            value: it.value.en,
          }))}
        ></PropertySelect>

        {[
          { path: 'name', label: 'Naam' },
          { path: 'address.street', label: 'Straat' },
          { path: 'address.postalcode', label: 'Postcode' },
          { path: 'address.place', label: 'Plaats' },
          { path: 'detail.KI', label: 'KI' },
          { path: 'address.surface', label: 'Oppervlakte' },
          { path: 'own_ref', label: 'Eigen ref' },
        ].map((it) => (
          <PropertyText
            path={it.path}
            entityDetail={propertyDetail}
            setEntityDetail={setPropertyDetail}
            label={it.label}
            edit={viewState !== VIEW_STATES.VIEW}
          ></PropertyText>
        ))}
      </div>
    </EntityDetail>
  );
};
