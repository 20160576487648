import React, { useState, useContext, useEffect } from 'react';
import { EntityList } from '../components/EntityList';
import { DataContext } from '../provider/DataProvider';

import EntityDetail, {
  VIEW_STATES,
  PropertyText,
  PropertySelect,
  useEntityDetailPersistance,
} from '../components/EntityDetail';
export const ownerTemplate = () => ({
  address: {},
  type: '1',
});

export const ENTITY_NAME = 'owner';

export const useOwnerList = () => {
  const [{ owner }] = useContext(DataContext);
  const [ownerList, setOwnerList] = React.useState(undefined);

  useEffect(() => {
    if (owner.length > 0) {
      setOwnerList(
        owner.map((it) => ({
          key: it.own_ref ? it.own_ref : it._id,
          value: it.name,
        })),
      );
    }
  }, [owner]);

  return ownerList;
};

export const OwnerList = () => {
  return (
    <EntityList
      entityName={ENTITY_NAME}
      displayFields={[
        //{ name: 'ID', path: '_id' },
        { name: 'Naam', path: 'name' },
        { name: 'Straat', path: 'address.street' },
        { name: 'Plaats', path: 'address.place' },
        //{ name: 'type', path: 'type' },
        //{ name: 'Eigen  ref', path: 'own_ref' },
      ]}
    ></EntityList>
  );
};

const OwnerDetail = ({ ownerId }) => {
  const [
    _,
    { getValue, setValue, create, update, archive },
  ] = useEntityDetailPersistance(ownerId, ENTITY_NAME, true, ownerTemplate());

  const [viewState, setViewState] = useState(
    ownerId === 'new' ? VIEW_STATES.NEW : VIEW_STATES.VIEW,
  );

  return (
    <EntityDetail
      on_back="owner"
      viewState={viewState}
      setViewState={setViewState}
      create={create}
      update={update}
      archive={archive}
    >
      <div className="sm:gap-3 sm:grid-cols-3 md:grid-cols-4 grid items-center grid-cols-1">
        <PropertySelect
          path="type"
          getValue={() => getValue('type')}
          setValue={(val) => setValue('type', val)}
          label="type"
          edit={viewState !== VIEW_STATES.VIEW}
          keyVal={[
            { key: '1', value: 'BV' },
            { key: '2', value: 'prive' },
          ]}
        ></PropertySelect>

        {[
          { path: 'name', label: 'Naam' },
          { path: 'address.street', label: 'Straat' },
          { path: 'address.postalcode', label: 'Postcode' },
          { path: 'address.place', label: 'Plaats' },
          { path: 'email', label: 'email' },
          { path: 'phone', label: 'tel' },
          { path: 'iban', label: 'iban' },
          { path: 'enterprisenbr', label: 'Ondernemings nr.' },
          { path: 'own_ref', label: 'Eigen referentie' },
          { path: 'signature', label: 'Signature url' },
          { path: 'logo', label: 'logo' },
          { path: 'banner', label: 'Briefhoofd' },
          { path: 'signby', label: 'Ondertekening' },
        ].map((it) => (
          <PropertyText
            key={it.path}
            getValue={() => getValue(it.path)}
            setValue={(value) => setValue(it.path, value)}
            path={it.path}
            label={it.label}
            edit={viewState !== VIEW_STATES.VIEW}
          ></PropertyText>
        ))}
      </div>
    </EntityDetail>
  );
};

export default OwnerDetail;
