import React, { useContext } from 'react';
import {
  NavLink,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import LandingPage from '../components/rentmanagement/LandingPage';
import OwnerDetail, { OwnerList } from '../model/Owner';
import { PropertyDetail, PropertyList } from '../model/Property';

import {
  RentalContractDetail,
  RentalContractList,
} from '../model/RentalContract';

import Upload from '../components/rentmanagement/Upload';
import ContractComponent from '../components/rentmanagement/ContractComponent';
import Index from '../components/rentmanagement/IndexComponent';
import { UserContext } from '../provider/AuthProvider';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const RentManagement = () => {
  const [user, LoginLogout] = useContext(UserContext);
  let query = useQuery();

  return (
    <>
      {/* CONTENT */}

      {/* OTHER PAGES */}
      <div
        className={` ${
          window.location.pathname !== '/login'
            ? 'p-4 bg-white rounded-md shadow-md'
            : ''
        }`}
      >
        <Switch>
          <Route exact path="/login" component={LandingPage} />

          <Route exact path="/rental_contract" component={RentalContractList} />
          <Route
            exact
            path="/rental_contract/:contractId"
            component={(params) => (
              <>
                <RentalContractDetail
                  contractId={params.match.params.contractId}
                />
              </>
            )}
          />
          <Route
            exact
            path="/owner/:ownerId"
            component={(params) => (
              <OwnerDetail ownerId={params.match.params.ownerId} />
            )}
          />
          <Route exact path="/owner" component={OwnerList} />
          <Route exact path="/property" component={PropertyList} />
          <Route
            exact
            path="/property/:propertyId"
            component={(params) => (
              <PropertyDetail propertyId={params.match.params.propertyId} />
            )}
          />

          <Route exact path="/load" component={(params) => <Upload />} />

          <Route
            exact
            path="/index"
            component={(params) => (
              <Index
                indexMonth={query.get('idx_month')}
                indexYear={query.get('idx_year')}
              />
            )}
          />
          <Route component={(params) => <LandingPage user={user} />} />
        </Switch>
      </div>
    </>
  );
};
