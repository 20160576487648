import React, { useState, useContext, useEffect, Fragment } from 'react';
import styles from './RentAdjustment.module.css';
import { DataContext } from '../../provider/DataProvider';
import ReactToPdf from 'react-to-pdf';
import { useHistory } from 'react-router-dom';
import { createEntity } from '../../firebase';
import {
  getIndexYear,
  getContractLanguage,
  getPropertyLabel,
} from '../../model/StaticProperties';
import { RentAdjustmentDetail } from './RentAdjustmentDetail';
import { toFixed } from '../../util/util';

export const trans = () => {
  const month = {
    nl: [
      'januari',
      'februari',
      'maart',
      'april',
      'mei',
      'juni',
      'juli',
      'augustus',
      'september',
      'oktober',
      'november',
      'december',
    ],
    fr: [
      'janvier',
      'février',
      'mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre',
    ],
  };
  return {
    nl: {
      l2: (data) => {
        return `${
          data.contract.detail.caption
            ? data.contract.detail.caption
            : 'Geachte,'
        }`;
      },
      l3: (data) => {
        return `Betreft indexering huurprijs van ${
          data.detail.type ? data.detail.type : 'het pand'
        }: ${data.property.address.street}, ${
          data.property.address.postalcode
        } ${data.property.address.place}`;
      },
      l4: (data) => {
        return `Wij verwijzen naar onze huurovereenkomst van in rubriek vermeld pand en delen U mede dat de huurprijs aangepast wordt aan de index volgens formule vermeld in voorgenoemde overeenkomst:`;
      },
      l5: (data) => {
        return `${toFixed(
          data.contract.detail.rent,
          2,
        )}€(basis huur) X ${toFixed(data.detail.newIndex, 4)} (index: ${
          data.detail.newIndexMonthYear
        } ) `;
      },
      l6: (data) => {
        return `${toFixed(data.detail.baseIndexRebased, 4)} (basisindex: ${
          data.detail.indexBaseMonthYear
        })`;
      },
      l7: (data) => {
        return `= ${toFixed(data.detail.rent, 2)} €`;
      },
      l8: (data) => {
        return (
          data.contract.detail.rent && (
            <Fragment>
              <div className={styles.l8}>{`Vanaf ${
                data.detail.nextPaymentMonth
              } is de huur${
                data.contract.detail.vat ? ' excl BTW' : ''
              }:`}</div>
              <div className={`${styles.l8} text-right mr-12`}>
                {toFixed(data.detail.rent, 2)}€
              </div>
            </Fragment>
          )
        );
      },
      l9: (data) => {
        return (
          data.contract.detail.charges &&
          data.contract.detail.charges.length > 0 && (
            <Fragment>
              <div className={styles.l9}>{`Maandelijks voorschot kosten${
                data.contract.detail.vat ? ' excl BTW' : ''
              }:`}</div>
              <div className={`${styles.l9} text-right mr-12`}>
                {toFixed(data.contract.detail.charges, 2)}€
              </div>
            </Fragment>
          )
        );
      },
      l9b: (data) => {
        return (
          data.contract.detail.rv &&
          data.contract.detail.rv.length > 0 && (
            <Fragment>
              <div
                className={styles.l9}
              >{`Maandelijks voorschot onroerende voorheffing${
                data.contract.detail.vat ? ' excl BTW' : ''
              }:`}</div>
              <div className={`${styles.l9} text-right mr-12`}>
                {toFixed(data.contract.detail.rv, 2)}€
              </div>
            </Fragment>
          )
        );
      },
      l9c: (data) => {
        return (
          data.contract.detail.vat &&
          data.contract.detail.vat.length > 0 && (
            <Fragment>
              <div className={styles.l9}>{`BTW (21%)`}</div>
              <div className={`${styles.l9} text-right mr-12`}>
                {toFixed(data.detail.vat, 2)}€
              </div>
            </Fragment>
          )
        );
      },
      l10: (data) => {
        return (
          <Fragment>
            <div
              className={styles.l10}
            >{`Totaal van de nieuwe huurprijs vanaf 01/${data.detail.nextPaymentMonth} bedraagt:`}</div>
            <div className={`${styles.l10} text-right mr-12`}>
              {toFixed(data.detail.monthlyDue, 2)}€
            </div>
          </Fragment>
        );
      },
      l11: (data) => {
        return `${
          data.owner.iban
            ? `Te storten op rekening nummer ${data.owner.iban}`
            : ''
        }`;
      },
      l12: (data) => {
        return `Wij danken U en tekenen met de meeste hoogachting,`;
      },
      month: month['nl'],
    },
    fr: {
      l2: (data) => {
        return `${
          data.contract.detail.caption ? data.contract.detail.caption : 'Cher,'
        }`;
      },
      l3: (data) => {
        return `Concerne ${data.detail.type ? data.detail.type : 'le bien'}: ${
          data.property.address.street
        }, ${data.property.address.postalcode} ${data.property.address.place}`;
      },

      l4: (data) => {
        return `Nous nous referons au bail du bien type ${data.detail.type} que vous occupez. Le bail prévoit que le loyer sera adapté une fois par an à l'index, et ce selon la formule suivante:`;
      },
      l5: (data) => {
        return `${data.contract.detail.rent}€(loyer de base) X ${data.detail.newIndex} (index: ${data.detail.newIndexMonthYear} ) `;
      },
      l6: (data) => {
        return `${data.detail.baseIndexRebased} (index de base: ${data.detail.indexBaseMonthYear})`;
      },
      l7: (data) => {
        return `= ${data.detail.rent} €`;
      },
      l8: (data) => {
        return (
          data.contract.detail.rent && (
            <Fragment>
              <div
                className={styles.l8}
              >{`A partir de ${data.detail.nextPaymentMonth} le loyer est de:`}</div>
              <div className={styles.l8}>{data.detail.rent}€</div>
            </Fragment>
          )
        );
      },
      l9: (data) => {
        return (
          data.contract.detail.charges &&
          data.contract.detail.charges.length > 0 && (
            <Fragment>
              <div className={styles.l9}>{`Provision charges:`}</div>
              <div className={styles.l9}>{data.contract.detail.charges}€</div>
            </Fragment>
          )
        );
      },
      l9b: (data) => {
        return (
          data.contract.detail.rv &&
          data.contract.detail.rv.length > 0 && (
            <Fragment>
              <div
                className={styles.l9}
              >{`Provision précompte immobilier:`}</div>
              <div className={styles.l9}>{data.contract.detail.rv}€</div>
            </Fragment>
          )
        );
      },

      l10: (data) => {
        return (
          <Fragment>
            <div
              className={styles.l10}
            >{`Total du versement mensuel est à partir de 01/${data.detail.nextPaymentMonth}:`}</div>
            <div className={styles.l10}>{data.detail.monthlyDue}€</div>
          </Fragment>
        );
      },
      l11: (data) => {
        return `A verser sur le compte N° ${data.owner.iban}`;
      },
      l12: (data) => {
        return `En vous remerciant par avance,`;
      },
      month: month['fr'],
    },
  };
};
const RentAdjustment = ({
  contractId,
  download,
  publish,
  indexationId,
  disablePDF,
}) => {
  const [data] = useContext(DataContext);
  //const {owner, property, tenant, contract}
  const [ready, setState] = useState(false);
  const [contract, setContract] = useState(undefined);
  const [owner, setOwner] = useState(undefined);
  const [property, setProperty] = useState(undefined);
  const [lData, setData] = useState(undefined);
  const [lang, setLanguage] = useState('nl');
  const ref = React.createRef();
  let history = useHistory();

  const translation = trans();

  const getDetail = (contractRentAdjustment) => {
    //newIndex: {indexMonth, indexYear, indexNumber}
    let lang = getContractLanguage(
      contractRentAdjustment.contract.detail.language,
    );

    let rent = contractRentAdjustment.new_rent;

    let baseIndexMonth = contractRentAdjustment.detail.indexBaseMonthYear.split(
      '/',
    )[0];
    let currentMonth = new Date().getMonth();

    return {
      monthlyDue: (
        Number(rent) +
        (contractRentAdjustment.contract.detail.charges
          ? Number(contractRentAdjustment.contract.detail.charges)
          : 0) +
        (contractRentAdjustment.contract.detail.rv
          ? Number(contractRentAdjustment.contract.detail.rv)
          : 0)
      ).toFixed(2),
      rent: rent,
      newIndex: contractRentAdjustment.new_index,
      newIndexYear: contractRentAdjustment.indexYear,
      newIndexMonthYear: `${
        translation[lang]['month'][contractRentAdjustment.new_index_month - 1]
      } ${contractRentAdjustment.indexYear}`,
      baseIndexRebased: contractRentAdjustment.baseIndexRebased,
      nextPaymentMonth: `${
        translation[lang]['month'][(currentMonth + 1) % 12]
      } ${new Date().getFullYear() + ((currentMonth + 1) % 12 === 0 ? 1 : 0)}`,
      indexBaseMonthYear: `${translation[lang]['month'][baseIndexMonth - 1]} ${
        contractRentAdjustment.detail.indexBaseMonthYear.split('/')[1]
      }`,
      type: getPropertyLabel(contractRentAdjustment.property.type, lang),
    };
  };

  useEffect(() => {
    const { contractRentAdjustment } = data;

    console.dir(data);
    if (!indexationId && contractRentAdjustment.length > 0) {
      let contractRentAdjustmentItem = contractRentAdjustment.filter(
        (adj) => adj.contract._id === contractId,
      )[0];
      console.dir(contractRentAdjustmentItem);

      setData({
        contractRentAdjustment: {
          ...contractRentAdjustmentItem,
          detail: getDetail(contractRentAdjustmentItem),
        },
        detail: getDetail(contractRentAdjustmentItem),
      });
      setLanguage(
        getContractLanguage(
          contractRentAdjustmentItem.contract.detail.language,
        ),
      );
      setState(false);
    } else if (contractRentAdjustment.length > 0) {
      let contractRentAdjustmentItem = contractRentAdjustment.filter(
        (adj) => adj._id === indexationId,
      )[0];
      console.dir(contractRentAdjustmentItem);
      setData({
        contractRentAdjustment: contractRentAdjustmentItem,
        detail: contractRentAdjustmentItem.detail,
      });
      setLanguage(
        getContractLanguage(
          contractRentAdjustmentItem.contract.detail.language,
        ),
      );
    } else setState(false);
  }, [data]);

  useEffect(() => {
    if (ready && download) {
      testToPdf && testToPdf();
    }
  }, [ready]);

  let [year, month, day] = new Date().toISOString().split('T')[0].split('-');

  let testToPdf = false;

  return lData && lData.detail && lData.contractRentAdjustment ? (
    <div>
      <div className="btn-grp">
        {!disablePDF && (
          <ReactToPdf
            targetRef={ref}
            filename={`${year}${month}${day} ${lData.contractRentAdjustment.contract.name} - ${lData.contractRentAdjustment.property.own_ref}.pdf`}
            onComplete={(data) => {
              download && history.go(-1);
            }}
          >
            {({ toPdf }) => {
              testToPdf = toPdf;
              return (
                <button
                  onClick={toPdf}
                  className="hover:bg-indigo-500 focus:outline-none hover:shadow-lg focus:shadow-outline px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md shadow"
                >
                  Generate pdf
                </button>
              );
            }}
          </ReactToPdf>
        )}
        {publish && (
          <button
            onClick={() =>
              createEntity(
                'contractRentAdjustment',
                lData.contractRentAdjustment,
              ).then(() => history.go(-1))
            }
          >
            publish
          </button>
        )}
      </div>
      <div ref={ref}>
        <RentAdjustmentDetail
          contractRentAdjustment={lData.contractRentAdjustment}
        />
      </div>
    </div>
  ) : (
    <div>Loading contract ${indexationId}.. </div>
  );
};

export default RentAdjustment;
