import React, { useContext, useState, useEffect, Fragment } from 'react';
import { EntityList } from '../components/EntityList';
import {
  CONTRACT_LANGUAGE,
  INDEX_YEARS,
  CONTRACT_REGION,
} from './StaticProperties';
import EntityDetail, {
  VIEW_STATES,
  PropertyText,
  PropertySelect,
  useEntityDetailPersistance,
} from '../components/EntityDetail';
import { useOwnerList } from './Owner';

export const rentalContractTemplate = () => ({
  tenant: {
    address: {},
    userPropertyAddress: false,
  },
  property: {
    address: {},
  },
  detail: {
    language: '2',
    region: '1',
  },
  owner: { key: '-' },
});

export const ENTITY_NAME = 'rental_contract';

export const RentalContractList = () => {
  return (
    <EntityList
      entityName={ENTITY_NAME}
      sortPath="property"
      displayFields={[
        { name: 'naam', path: 'tenant.name' },
        { path: 'property.type', name: 'type' },
        { path: 'property.address.street', name: 'straat' },
        { path: 'property.address.place', name: 'plaats' },
        { path: 'detail.signdate', name: `datum handtekening` },
        { path: 'detail.startdate', name: 'datum start' },
        { path: 'detail.rent', name: 'start huur' },
        { path: 'detail.charges', name: 'prov. kosten' },
        { path: 'detail.provrv', name: 'prov. OV' },
        {
          path: 'archive',
          name: 'Archief',
          render: (archived) =>
            archived ? (
              <i className="far fa-check-square"></i>
            ) : (
              <i className="far fa-square"></i>
            ),
        },
        { name: 'eigneaar', path: 'owner.key' },
      ]}
    ></EntityList>
  );
};

export const RentalContractDetail = ({ contractId, custcomponent }) => {
  const [viewState, setViewState] = useState(
    contractId === 'new' ? VIEW_STATES.NEW : VIEW_STATES.VIEW,
  );

  const [
    _,
    { getValue, setValue, create, update, archive },
  ] = useEntityDetailPersistance(
    contractId,
    ENTITY_NAME,
    true,
    rentalContractTemplate(),
  );

  const ownerList = useOwnerList();

  useEffect(() => {
    ownerList &&
      contractId === 'new' &&
      setValue('owner.key', ownerList[0].key);
  }, [ownerList]);
  return (
    <>
      {custcomponent ? (
        custcomponent()
      ) : (
        <EntityDetail
          on_back={ENTITY_NAME}
          viewState={viewState}
          setViewState={setViewState}
          create={create}
          update={update}
          archive={archive}
          archived={getValue && getValue('archive')}
        >
          <div className="sm:gap-3 sm:grid-cols-3 md:grid-cols-4 grid items-center grid-cols-1">
            {ownerList && (
              <>
                <PropertySelect
                  path="owner.key"
                  getValue={() => getValue('owner.key')}
                  setValue={(value) => setValue('owner.key', value)}
                  label="Eigenaar"
                  edit={viewState !== VIEW_STATES.VIEW}
                  keyVal={ownerList}
                ></PropertySelect>

                <PropertySelect
                  path="detail.region"
                  getValue={() => getValue('detail.region')}
                  setValue={(value) => setValue('detail.region', value)}
                  label="Regio"
                  edit={viewState !== VIEW_STATES.VIEW}
                  keyVal={CONTRACT_REGION.map((it) => {
                    return { key: it.key, value: it.value.nl };
                  })}
                ></PropertySelect>
              </>
            )}

            {[
              { path: 'property.type', label: 'Pand type' },
              { path: 'tenant.name', label: 'Huurder naam' },
              { path: 'tenant.caption', label: 'Huurder aanspreking' },
              { path: 'property.address.street', label: 'Pand straat' },
              { path: 'property.address.postalcode', label: 'Pand postcode' },
              { path: 'property.address.place', label: 'Pand plaats' },
              {
                path: 'tenant.address.street',
                label: 'Huurder straat (cor. adres)',
              },
              {
                path: 'tenant.address.postalcode',
                label: 'Huurder postcode (cor. adres)',
              },
              {
                path: 'tenant.address.place',
                label: 'Huurder plaats (cor. adres)',
              },
              { path: 'detail.signdate', label: 'Datum handtekening' },
              { path: 'detail.startdate', label: 'Datum start' },
              { path: 'detail.rent', label: 'Start huur', type: 'number' },
              {
                path: 'detail.charges',
                label: 'Provisie kosten',
                type: 'number',
              },
              { path: 'detail.provrv', label: 'Provisie OV', type: 'number' },
              { path: 'detail.vat', label: 'BTW' },
            ].map((it) => (
              <PropertyText
                key={it.path}
                getValue={() => getValue(it.path)}
                setValue={(value) => setValue(it.path, value)}
                path={it.path}
                label={it.label}
                type={it.type}
                edit={viewState !== VIEW_STATES.VIEW}
              ></PropertyText>
            ))}

            <PropertySelect
              path="detail.language"
              getValue={() => getValue('detail.language')}
              setValue={(value) => setValue('detail.language', value)}
              label="Taal"
              edit={viewState !== VIEW_STATES.VIEW}
              keyVal={CONTRACT_LANGUAGE.map((it) => ({
                key: it.key,
                value: it.value.en,
              }))}
            ></PropertySelect>
          </div>
        </EntityDetail>
      )}
    </>
  );
};
