import React, { useEffect } from 'react';

export const PROPERTY_TYPE = [
  {
    key: '2',
    value: { en: 'appartment', fr: 'appartement', nl: 'appartement' },
  },
  { key: '3', value: { en: 'garage', fr: 'garage', nl: 'garage' } },
  { key: '1', value: { en: 'house', fr: 'maison', nl: 'huis' } },
  { key: '4', value: { en: 'office', fr: 'bureau', nl: 'kantoor' } },
  { key: '5', value: { en: 'opslag', fr: 'opslag', nl: 'opslag' } },
  { key: '6', value: { en: 'toonzaal', fr: 'toonzaal', nl: 'toonzaal' } },
  { key: '7', value: { en: 'berging', fr: 'berging', nl: 'berging' } },
  { key: '8', value: { en: 'loods', fr: 'loods', nl: 'loods' } },
  {
    key: '9',
    value: {
      en: 'kantoor en magazijn',
      fr: 'kantoor en magazijn',
      nl: 'kantoor en magazijn',
    },
  },
  { key: '10', value: { en: 'magazijn', fr: 'magazijn', nl: 'magazijn' } },
  { key: '11', value: { en: 'parking', fr: 'parking', nl: 'parking' } },
  { key: '12', value: { en: 'hangar', fr: 'hangar', nl: 'hangar' } },
  { key: '13', value: { en: 'terrain', fr: 'terrain', nl: 'terrein' } },
  { key: '14', value: { en: 'buildings', fr: 'batiments', nl: 'gebouwen' } },
  { key: '15', value: { en: 'property', fr: 'bien', nl: 'pand' } },
];

export const getPropertyLabel = (code, lang) => {
  let res = PROPERTY_TYPE.filter((it) => Number(it.key) === Number(code))[0];

  console.log(`${code} ${lang}`);
  return res ? (res.value[lang] ? res.value[lang] : res.value['en']) : 'house';
};

export const CONTRACT_LANGUAGE = [
  { key: '1', value: { en: 'fr', nl: 'Frans' } },
  { key: '2', value: { en: 'nl', nl: 'Nederlands' } },
  { key: '3', value: { en: 'en', nl: 'Engels' } },
];

export const CONTRACT_REGION = [
  { key: '1', value: { en: 'fr', nl: 'Vlaams gewest geen bewoning' } },
  { key: '4', value: { en: 'fr', nl: 'Vlaams gewest bewoning' } },
  { key: '2', value: { en: 'nl', nl: 'Waals gewest' } },
  { key: '3', value: { en: 'en', nl: 'Brussels Hoofdstedelijk gewest' } },
];

export const getContractLanguage = (code) => {
  let res = CONTRACT_LANGUAGE.filter(
    (it) => Number(it.key) === Number(code),
  )[0];
  return res ? res.value.en : 'fr';
};

export const INDEX_YEARS = [
  { key: '1', value: '1988', coef: 1.65658 },
  { key: '2', value: '1996', coef: 1.37404 },
  { key: '3', value: '2004', coef: 1.20771 },
  { key: '4', value: '2013', coef: 1.0 },
];

export const getIndexYear = (code) => {
  let res = INDEX_YEARS.filter((it) => Number(it.key) === Number(code))[0];
  return res ? res : undefined;
};

export const getMonth = (key, lang) => {
  return INDEX_MONTHS.filter((it) => it.key === parseInt(key)).reduce(
    (acc, it) => it.value[lang],
    undefined,
  );
};

export const INDEX_MONTHS = [
  { key: 1, value: { en: 'January', fr: 'Janvier', nl: 'Januari' } },
  { key: 2, value: { en: 'February', fr: 'Février', nl: 'Februari' } },
  { key: 3, value: { en: 'March', fr: 'Mars', nl: 'Maart' } },
  { key: 4, value: { en: 'April', fr: 'Avril', nl: 'April' } },
  { key: 5, value: { en: 'May', fr: 'Mai', nl: 'Mei' } },
  { key: 6, value: { en: 'June', fr: 'Juin', nl: 'Juni' } },
  { key: 7, value: { en: 'July', fr: 'Juillet', nl: 'Juli' } },
  { key: 8, value: { en: 'August', fr: 'Aout', nl: 'Augustus' } },
  { key: 9, value: { en: 'September', fr: 'Septembre', nl: 'September' } },
  { key: 10, value: { en: 'October', fr: 'Octobre', nl: 'Oktober' } },
  { key: 11, value: { en: 'November', fr: 'Novembre', nl: 'November' } },
  { key: 12, value: { en: 'December', fr: 'Décembre', nl: 'December' } },
];
