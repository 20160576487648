import React, { useState, useContext, useEffect, Fragment } from 'react';
import {
  ownerTemplate,
  ENTITY_NAME as OWNER_ENTITY_NAME,
} from '../../model/Owner';
import {
  propertyTemplate,
  ENTITY_NAME as PROPERTY_ENTITY_NAME,
} from '../../model/Property';
import {
  rentalContractTemplate,
  ENTITY_NAME as RENTAL_CONTRACT_ENTITY_NAME,
} from '../../model/RentalContract';

import { createEntity, IndexFigures } from '../../firebase';
import CSVReader from 'react-csv-reader';
import { DataContext } from '../../provider/DataProvider';

const loadArray = (data, entityname, template, createEntity) => {
  const headers = data.shift();
  data.map((el) => {
    let owner = template();
    if (el.length > 1) {
      el.map((value, i) => {
        let path = headers[i].split('.');
        let prop = owner;
        path.map((el, i) => {
          if (i !== path.length - 1) {
            if (!prop.hasOwnProperty(el)) prop[el] = {};
            prop = prop[el];
          } else {
            prop[el] = value;
          }
        });
      });
      console.log(`-${el}-${el.length}`);
      createEntity(entityname, owner, true);
    }
  });
};

const Upload = () => {
  const [data, { refresh, del_ent, createEntity }] = useContext(DataContext);

  const loadData = (data, entityname, template) => {
    console.dir({ data, entityname });
    del_ent(entityname, true, (res) => {
      console.dir(res);
      loadArray(data, entityname, template, createEntity);
    });
  };

  const downloadTxtFile = () => {
    [
      {
        name: 'owner',
        fields: [
          'name',
          'address.street',
          'address.postalcode',
          'address.place',
          'email',
          'phone',
          'iban	',
          'enterprisenbr',
          'type	',
          'own_ref',
          'banner',
          'signature',
          'signby',
        ],
      },
      {
        name: 'rental_contract',
        fields: [
          'owner.key',
          'detail.charges',
          'detail.language',
          'detail.provrv',
          'detail.region',
          'detail.rent',
          'detail.vat',
          'detail.rent',
          'detail.signdate',
          'detail.startdate',
          'property.address.street',
          'property.address.postalcode',
          'property.address.place',
          'property.type',
          'tenant.address.street',
          'tenant.address.postalcode',
          'tenant.address.place',
          'tenant.caption',
          'tenant.name',
          'archive',
        ],
      },
    ].map((it) => {
      let content = it.fields.reduce((acc, it) => acc + `${it};`, '') + '\n';

      data[it.name].map((i) => {
        content +=
          it.fields
            .map((fieldN) => {
              return fieldN.split('.').reduce((acc, path) => acc[path], i);
            })
            .join(';') + '\n';
      });

      const element = document.createElement('a');
      const file = new Blob([content], {
        type: 'text/plain;charset=utf-8',
      });
      element.href = URL.createObjectURL(file);
      element.download = `${it.name}.csv`;
      document.body.appendChild(element);
      element.click();
    });
  };

  return (
    <div>
      <div>
        <button onClick={downloadTxtFile}>Download</button>
      </div>
      Owners:
      <CSVReader
        onFileLoaded={(data, fileInfo) =>
          loadData(data, OWNER_ENTITY_NAME, ownerTemplate)
        }
      />
      Rental Contracts:
      <CSVReader
        onFileLoaded={(data, fileInfo) =>
          loadData(data, RENTAL_CONTRACT_ENTITY_NAME, rentalContractTemplate)
        }
      />
      {/*
      <div>
        {Object.keys(IndexFigures).map((year) =>
          Object.keys(IndexFigures[year]).map((month) => (
            <p>{`${year}_${month},${IndexFigures[year][month]}`}</p>
          )),
        )}
      </div>
      */}
    </div>
  );
  //<input id="image-file" type="file"  onChange={(e) => showFile(e)} />
};

export default Upload;
