import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  Fragment,
} from 'react';

import { DataContext } from '../../provider/DataProvider';
import { ContractRentAdjustmentList } from '../../model/ContractRentAdjustment';
import { INDEX_MONTHS } from '../../model/StaticProperties';
import RentAdjustmentDetail from './RentAdjustmentDetail';
import { IndexFigures } from '../../firebase';
import styles from './RentAdjustment.module.css';

const QUERY_STATE = 1;
const DETAIL_STATE = 2;
const PRINT_ALL = 3;
const DETAIL_VIEW = 4;
const [
  CURRENT_YEAR,
  CURRENT_MONTH,
  CURRENT_DAY,
] = new Date().toISOString().split('T')[0].split('-');
const ACTION_CHANGE_VIEW = 0;

const reducer = (state, action) => {
  console.dir(action);
  switch (action.type) {
    case ACTION_CHANGE_VIEW:
      return { ...state, view: action.view, detail: action.detail };
  }
  return state;
};

const IndexForm = ({ indexMonth, indexYear }) => {
  const [state, dispatch] = useReducer(reducer, {
    view: indexMonth ? DETAIL_STATE : QUERY_STATE,
  });
  const [indexData, setIndexData] = useState({
    month: indexMonth ? indexMonth : parseInt(CURRENT_MONTH),
    year: indexYear ? indexYear : CURRENT_YEAR,
  });
  const [data, { calculateIndex }] = useContext(DataContext);
  const YearList = Object.keys(IndexFigures).reverse();
  const IndexAvailable = (year, month) => {
    const idxM = `${month - 1 === 0 ? 12 : month - 1}`.padStart(2, 0);
    const idxY = month - 1 === 0 ? year - 1 : year;

    return IndexFigures[idxY] && IndexFigures[idxY][idxM] !== undefined;
  };

  const handleInputChange = (event) => {
    setIndexData({ ...indexData, [event.target.name]: event.target.value });
    console.dir(indexData);
  };

  useEffect(() => {
    if (data.contractRentAdjustmentData)
      setIndexData({
        month: data.contractRentAdjustmentData.indexMonth,
        year: data.contractRentAdjustmentData.indexYear,
      });

    if (indexYear && indexMonth) {
      calculateIndex(indexData.month, indexData.year);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    calculateIndex(indexData.month, indexData.year);
    dispatch({ type: ACTION_CHANGE_VIEW, view: DETAIL_STATE });
  };

  return (
    <div>
      {state.view === QUERY_STATE && (
        <form onSubmit={handleSubmit}>
          <div className="md:flex-no-wrap flex flex-row flex-wrap justify-center p-2 mx-auto">
            <div className="md:w-4/12 w-full">
              <label htmlFor="month" className="cursor-pointer">
                Te indexeren maand
              </label>
              <select
                onChange={handleInputChange}
                id="month"
                name="month"
                value={indexData.month}
                className="form-select appearance-none"
              >
                {INDEX_MONTHS.map((month) => (
                  <option key={month.key} value={month.key}>
                    {month.value.nl}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-span-1">
              <label>Jaar van indexatie</label>
              <select
                name="year"
                id="year"
                title="Jaar van indexatie"
                onChange={handleInputChange}
                value={indexData.year}
                className="form-select appearance-none"
              >
                {YearList.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {!IndexAvailable(indexData.year, indexData.month) && (
            <div className="mt-4 mb-2 text-center text-red-600">
              Index cijfer is nog niet beschikbaar voor {indexData.month}/
              {indexData.year}
            </div>
          )}

          <div className="mt-4 mb-2 text-center">
            <button
              type="submit"
              disabled={!IndexAvailable(indexData.year, indexData.month)}
              className={`${
                IndexAvailable(indexData.year, indexData.month)
                  ? 'hover:bg-indigo-500'
                  : 'opacity-50'
              } focus:outline-none hover:shadow-lg focus:shadow-outline px-5 py-3 text-base font-medium text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md shadow`}
            >
              Bereken indexatie
            </button>
          </div>
        </form>
      )}

      {state.view === DETAIL_STATE &&
        data.contractRentAdjustment &&
        data.contractRentAdjustment.length > 0 && (
          <div>
            <div className="flex flex-row flex-wrap justify-between px-3 py-2 mb-3 text-blue-600 bg-gray-300 rounded-md shadow-md">
              <div>
                <span>Indexatie voor:&nbsp;</span>
                <span className="">
                  {` ${INDEX_MONTHS[indexData.month - 1].value.nl} ${
                    indexData.year
                  }`}
                </span>
              </div>
              <div>
                <span>
                  Aangepaste huurprijzen vanaf 01/{indexData.month}/
                  {indexData.year}&nbsp;
                </span>
              </div>
            </div>
            <ContractRentAdjustmentList
              listActions={() => (
                <div className="flex flex-row justify-center">
                  <div className="p-2 mt-2">
                    <button
                      className="hover:bg-indigo-500 focus:outline-none hover:shadow-lg focus:shadow-outline px-5 py-3 text-base font-medium text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md shadow"
                      onClick={() =>
                        dispatch({
                          type: ACTION_CHANGE_VIEW,
                          view: QUERY_STATE,
                        })
                      }
                    >
                      Terug
                    </button>
                  </div>
                  <div className="p-2 mt-2">
                    <button
                      className="hover:bg-indigo-500 focus:outline-none hover:shadow-lg focus:shadow-outline px-5 py-3 text-base font-medium text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md shadow"
                      onClick={() =>
                        dispatch({
                          type: ACTION_CHANGE_VIEW,
                          view: PRINT_ALL,
                        })
                      }
                    >
                      View ALL
                    </button>
                  </div>
                </div>
              )}
              customActions={(key) => (
                <div className="flex flex-row">
                  <button
                    title="Details"
                    onClick={() =>
                      dispatch({
                        type: ACTION_CHANGE_VIEW,
                        view: DETAIL_VIEW,
                        detail: key,
                      })
                    }
                  >
                    <svg
                      className="hover:text-indigo-600 w-6 h-6 rounded-full"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>

                  <div>
                    {/*<Link to={`adj_d/${key.contract._id}`} title="Download">
                      <svg
                        className="hover:text-indigo-600 w-6 h-6 rounded-full"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                    </Link>*/}
                    {/*<RentAdjustment contractId={`${key}`}></RentAdjustment>*/}
                  </div>
                </div>
              )}
            ></ContractRentAdjustmentList>
          </div>
        )}

      {state.view === DETAIL_VIEW && (
        <Fragment>
          <div className="flex flex-row justify-end p-2 mt-2">
            <button
              className="hover:bg-indigo-500 focus:outline-none hover:shadow-lg focus:shadow-outline px-5 py-3 text-base font-medium text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md shadow"
              onClick={() =>
                dispatch({
                  type: ACTION_CHANGE_VIEW,
                  view: DETAIL_STATE,
                })
              }
            >
              Terug
            </button>
          </div>

          <RentAdjustmentDetail contractRentAdjustment={state.detail} />
        </Fragment>
      )}
      {state.view === PRINT_ALL && (
        <div>
          <div className="flex flex-row justify-end">
            <button
              className="hover:bg-indigo-500 focus:outline-none hover:shadow-lg focus:shadow-outline px-5 py-3 m-2 text-base font-medium text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md shadow"
              onClick={() => {
                window.print();
              }}
            >
              PRINT
            </button>
            <button
              className="hover:bg-indigo-500 focus:outline-none hover:shadow-lg focus:shadow-outline px-5 py-3 m-2 text-base font-medium text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md shadow"
              onClick={() => {
                dispatch({
                  type: ACTION_CHANGE_VIEW,
                  view: DETAIL_STATE,
                });
              }}
            >
              Terug
            </button>
          </div>
          <RentadjustmentsToPrint
            contractRentAdjustment={data.contractRentAdjustment}
            month={indexData.month}
            year={indexData.year}
          />
        </div>
      )}
    </div>
  );
};

const RentadjustmentsToPrint = ({ contractRentAdjustment, month, year }) => {
  return (
    <>
      <div className={styles.rentadjustmentheader}>
        <div>
          Indexaties voor {month}/{year} aangepaste huurprijzen vanaf 01/{month}
          /{year}
        </div>
        <ContractRentAdjustmentList readonly={true} />
      </div>
      <div className={`${styles.rentadjustmentlist}`}>
        {contractRentAdjustment.map((adj) => (
          <div className={` ${styles.pagebreak}`}>
            <RentAdjustmentDetail contractRentAdjustment={adj} />
          </div>
        ))}
      </div>
    </>
  );
};

export default IndexForm;
