export const flattenData = (obj) => {
  var res = flattenDataInt(obj);

  var result = {};
  res.map((val) => (result = { ...result, ...val }));
  console.dir(res);
  console.dir(result);
  return result;
};

const flattenDataInt = (obj, path) => {
  const keys = Object.keys(obj);
  const newPath = path !== undefined ? `${path}.` : ``;

  const keyValue = [];
  keys.map((key) => {
    keyValue.push(
      typeof obj[key] === 'object'
        ? flattenDataInt(obj[key], `${newPath}${key}`)
        : { [`${newPath}${key}`]: obj[key] },
    );
  });

  console.dir(keyValue.flat());
  return keyValue.flat();
};

export const toFixed = (val, digits) => {
  if (!isNaN(val)) {
    return Number(val).toLocaleString('nl-BE', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
  } else return val ? val : '';
};

export const deFlattenData = (obj) => {
  let result = {};
  Object.keys(obj).map((path) => {
    path.split('.').reduce((acc, key, idx, arr) => {
      if (acc[key] === undefined && idx < arr.length - 1) acc[key] = {};
      if (idx === arr.length - 1) acc[key] = obj[path];
      return acc[key];
    }, result);
  });
  console.dir(result);
  return result;
};
