import React, { useContext, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { DataContext } from '../provider/DataProvider';
import { NavLink } from 'react-router-dom';

const VIEW = 0;
const EDIT = 1;
const NEW = 2;

export const VIEW_STATES = { VIEW: VIEW, EDIT: EDIT, NEW: NEW };

export const PropertySelect = ({
  label,
  edit,
  path,
  keyVal,
  getValue,
  setValue,
}) => {
  return (
    <Fragment>
      <div className="col-span-1 px-2 py-2 my-1 rounded-md">
        {label ? label : path}
      </div>
      {edit ? (
        <select
          onChange={(e) => setValue(e.target.value)}
          name={path}
          value={getValue()}
          className="form-select-grid"
        >
          {keyVal.map((item) => (
            <option key={item.key} value={item.key}>
              {item.value}
            </option>
          ))}
        </select>
      ) : (
        <div className="sm:col-span-2 md:col-span-3 w-full h-full px-2 py-2 my-1 overflow-auto bg-gray-100 rounded-md">
          {getValue() !== undefined &&
          keyVal.filter((it) => it.key === getValue())[0] !== undefined &&
          getValue() !== ''
            ? keyVal.filter((it) => it.key === getValue())[0].value
            : '-'}
        </div>
      )}
    </Fragment>
  );
};

export const PropertyText = ({
  label,
  edit,
  path,
  getValue,
  setValue,
  type,
}) => {
  return (
    <Fragment>
      <div className=" px-2 py-2 my-1 rounded-md">{label ? label : path}</div>
      {edit ? (
        <input
          type={type ? type : 'text'}
          value={getValue()}
          onChange={(e) => setValue(e.target.value)}
          className="form-input-grid"
        />
      ) : (
        <div className="md:col-span-3 sm:col-span-2 w-full h-full px-2 py-2 my-1 overflow-auto bg-gray-100 rounded-md">
          {getValue()}
        </div>
      )}
    </Fragment>
  );
};

export const useEntityDetailPersistance = (
  entityId,
  entityName,
  secured,
  entityTemplate,
) => {
  const [entityDetail, setEntityDetail] = React.useState(
    entityId === 'new' ? entityTemplate : undefined,
  );
  const [data, { refresh, createEntity, updateEntity }] = useContext(
    DataContext,
  );

  const create = () => {
    createEntity(entityName, entityDetail, secured);
    refresh(entityName, secured);
  };
  const archive = () => {
    updateEntity(
      entityName,
      { ...entityDetail, archive: !entityDetail.archive },
      secured,
    );
    //refresh(entityName, secured);
  };
  const update = () => {
    updateEntity(entityName, entityDetail, secured);
    //refresh(entityName, secured);
  };

  const getValue = (path) => {
    let value =
      entityDetail !== undefined
        ? path
            .split('.')
            .reduce(
              (acc, key) => (acc && acc[key] ? acc[key] : undefined),
              entityDetail,
            )
        : undefined;
    return value ? value : '';
  };

  const setValue = (path, value) => {
    setEntityDetail((state) => {
      path.split('.').reduce((acc, key, i, pathList) => {
        if (i === pathList.length - 1) acc[key] = value;
        else return acc[key] ? acc[key] : {};
      }, state);
      return { ...state };
    });
  };

  useEffect(() => {
    data[entityName].length > 0 &&
      entityId !== 'new' &&
      setEntityDetail(
        data[entityName].filter((item) => item._id === entityId)[0],
      );
  }, [data, entityId, setEntityDetail, entityName]);

  return [
    entityDetail,
    { setEntityDetail, create, update, archive, getValue, setValue },
  ];
};

const EntitytDetail = ({
  children,
  on_back,
  viewState,
  setViewState,
  update,
  archive,
  archived,
  create,
}) => {
  const history = useHistory();

  return (
    <div>
      {children}
      <div className="flew-row flex justify-center mt-4 mb-2">
        {
          //view mode
          viewState === VIEW && (
            <Fragment>
              <button
                className="hover:bg-gray-400 bg-gray-300 text-gray-800 font-bold mx-1 py-2 px-4 rounded-l"
                onClick={() => {
                  setViewState(EDIT);
                }}
              >
                Aanpassen
              </button>

              <NavLink to={`/${on_back}`}>
                <button className="hover:bg-gray-400 bg-gray-300 text-gray-800 font-bold mx-1 py-2 px-4 rounded-l">
                  Annuleren
                </button>
              </NavLink>
            </Fragment>
          )
        }
        {
          //edit mode
          viewState === EDIT && (
            <Fragment>
              <button
                className="hover:bg-gray-400 bg-gray-300 text-gray-800 font-bold mx-1 py-2 px-4 rounded-l"
                onClick={() => {
                  update();
                  history.push(`/${on_back}`);
                }}
              >
                Opslaan
              </button>

              <button
                className="hover:bg-gray-400 bg-gray-300 text-gray-800 font-bold mx-1 py-2 px-4 rounded-l"
                onClick={() => {
                  archive();
                  history.push(`/${on_back}`);
                }}
              >
                {archived ? `DeArchiveren` : `Archiveren`}
              </button>

              <button
                className="hover:bg-gray-400 bg-gray-300 text-gray-800 font-bold mx-1 py-2 px-4 rounded-l"
                onClick={() => {
                  setViewState(VIEW);
                }}
              >
                Annuleren
              </button>
            </Fragment>
          )
        }
        {
          //edit mode
          viewState === NEW && (
            <Fragment>
              <button
                className="hover:bg-gray-400 bg-gray-300 text-gray-800 font-bold mx-1 py-2 px-4 rounded-l"
                onClick={() => {
                  create();
                  history.push(`/${on_back}`);
                }}
              >
                Opslaan
              </button>
              <NavLink to={`/${on_back}`}>
                <button className="hover:bg-gray-400 bg-gray-300 text-gray-800 font-bold mx-1 py-2 px-4 rounded-l">
                  Annuleren
                </button>
              </NavLink>
            </Fragment>
          )
        }
      </div>
    </div>
  );
};

export default EntitytDetail;
