import React, {
  useContext,
  Fragment,
  useState,
  useCallback,
  Children,
} from 'react';
import { DataContext } from '../provider/DataProvider';
import { NavLink } from 'react-router-dom';
import { toFixed } from '../util/util';

export const EntityList = ({
  applicationName,
  entityName,
  displayFields,
  customActions,
  sortPath,
  listActions,
  readonly,
}) => {
  const [data] = useContext(DataContext);
  const [listSortPath, setSortPath] = useState(undefined);

  const listSort = useCallback(
    (e1, e2) => {
      const lSrt = (e1, e2, pth) => {
        let pth1 = pth.shift();
        //console.log(`${e1[pth1]} < ${e2[pth1]}`);
        return pth.length === 0
          ? isNaN(e1[pth1]) && isNaN(e1[pth1])
            ? e1[pth1] < e2[pth1]
              ? -1
              : 1
            : Number(e1[pth1]) < Number(e2[pth1])
            ? 1
            : -1
          : lSrt(e1[pth1], e2[pth1], pth);
      };
      return listSortPath && lSrt(e1, e2, listSortPath.split('.'));
    },
    [listSortPath],
  );

  const EntityListHeader = ({ displayFields }) => {
    return (
      <Fragment>
        {displayFields.map((field, i) => (
          <div
            className="rounded-t-md p-4 font-bold text-left border-b-2 border-black border-solid cursor-pointer"
            onClick={() => {
              console.log(`CLICK ${field.path}`);
              setSortPath(field.path);
            }}
            key={i}
          >
            {field.name}
          </div>
        ))}

        {!readonly && (
          <div className="rounded-t-md p-4 font-bold text-left border-b-2 border-black border-solid cursor-pointer">
            Actie
          </div>
        )}
      </Fragment>
    );
  };

  const getValue = (path, entity, renderFunction) => {
    let val = path.split('.').reduce((acc, key, i, arr) => {
      return acc ? acc[key] : undefined;
    }, entity);
    return renderFunction ? renderFunction(val) : val;
  };

  const EntityLine = ({ displayFields, entity, entityName, readonly }) => (
    <Fragment>
      {displayFields.map((field, i) => (
        <div
          key={i}
          className={`p-4 border-b border-gray-400 border-solid ${
            !isNaN(getValue(field.path, entity, field.render)) && 'text-right'
          } `}
        >
          {toFixed(getValue(field.path, entity, field.render), 2)}
        </div>
      ))}
      {!readonly && (
        <div className="p-4 border-b border-gray-400 border-solid">
          {customActions ? (
            customActions(entity)
          ) : (
            <NavLink to={`${entityName}/${entity._id}`}>
              <i className="fas fa-edit"></i>
            </NavLink>
          )}
        </div>
      )}
    </Fragment>
  );

  return (
    <div>
      <div>
        {/* DO NOT REMOVE DIV & GRID-COLS CLASSES BELOW
        https://tailwindcss.com/docs/controlling-file-size#writing-purgeable-html */}
        <div className="hidden grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6 grid-cols-7 grid-cols-8 grid-cols-9 grid-cols-10 grid-cols-11 grid-cols-12"></div>
        {/*  */}
        <div
          className={`grid ${`grid-cols-${
            displayFields.length + (readonly ? 0 : 1)
          }`} overflow-x-auto`}
        >
          <EntityListHeader key="-1" displayFields={displayFields} />
          {data[entityName]
            .sort((e1, e2) => {
              return listSort && listSort(e1, e2);
            })
            .map((entity, key) => (
              <EntityLine
                key={key}
                displayFields={displayFields}
                entity={entity}
                entityName={entityName}
                readonly={readonly}
              ></EntityLine>
            ))}
        </div>
        {!readonly && !customActions && (
          <div className="mt-6 mb-2 text-center ">
            <Fragment>
              <NavLink
                to={`${
                  applicationName ? `/${applicationName}` : ``
                }/${entityName}/new`}
              >
                <button className="hover:bg-gray-400 bg-gray-300 text-gray-800 font-bold mx-1 py-2 px-4 rounded-l">
                  Nieuw
                </button>
              </NavLink>
            </Fragment>
          </div>
        )}
        {listActions && (
          <div className="mt-6 mb-2 text-center">
            <Fragment>{listActions()}</Fragment>
          </div>
        )}
      </div>
    </div>
  );
};
