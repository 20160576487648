import React, { Fragment, useState } from 'react';
import Index from './IndexComponent';
import ContractWizard from './ContractWizard';

const LandingPage = ({ user }) => {
  return (
    <Fragment>
      <ContractWizard />
      <div className="hidden sm:mx-auto container w-full px-3 mt-8">
        <div className="sm:rounded-lg sm:px-10 px-4 py-8 bg-white shadow">
          <p>
            Facere ipsum nihil ea et et tempore pariatur. Tenetur et et deleniti
            occaecati non. Voluptates occaecati doloremque eum. Et aut et et
            quis eligendi minima. Hic delectus est consequatur. Dolores ex ab
            eos. Ad molestiae at omnis rerum eos totam pariatur. Hic est sit
            facilis saepe repellat et enim maxime eos. Natus voluptas debitis
            impedit aperiam consequuntur. Et non veniam facere et vitae
            architecto inventore qui. Repellendus ipsum magni eos qui et
            reiciendis delectus fugit qui. Nesciunt iusto quaerat et rerum.
          </p>
          <br />
          <p>
            Dolore voluptas quos dolores ea nulla. Est voluptates nobis illum
            nam modi vel aut ut veniam. Deserunt amet a ratione dicta voluptas
            sit et laudantium accusantium. Quis sunt quia sint ut debitis magni.
            Facere et ea ex deserunt voluptatum repellat quam. Id natus eaque
            voluptate.
          </p>
          <br />
          <p>
            Fugit repudiandae ut modi mollitia voluptas officia excepturi.
            Cupiditate non qui eveniet blanditiis veritatis. Accusantium libero
            officiis quis sit et quae doloremque voluptate. Nam sit et quaerat
            et nobis vero id in. Et consequuntur deserunt quia. Eveniet quas
            ratione vero soluta minus ut consequatur. At exercitationem qui
            facere dignissimos distinctio. Aut et cum. Molestias molestiae
            voluptas rerum rem earum. Reprehenderit dolor molestias omnis
            voluptas dolore consequatur sunt illum et. Et sapiente ut molestiae
            sequi et maiores id. Placeat saepe nulla maxime voluptatem voluptas
            rerum soluta facere possimus. Et aut aperiam omnis odio et. Omnis
            minus voluptatum ipsa tenetur.
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default LandingPage;
